export const isInt = (val: any) => {
  const reg = /^[0-9]+$/gm;
  return reg.test(val);
};

export const preventNonNumeric = (event, disableFloat) => {
  // allow decimals if float enabled
  if (event.key === "Backspace") {
    return;
  }

  if (!disableFloat && event.key === ".") {
    return;
  }
  // if float is disabled prevent any non numerical key
  if (disableFloat && !isInt(event.key)) {
    event.preventDefault();
  }
  if (Number.isNaN(Number(event.key)) && ![8, 46].includes(event.keyCode)) {
    event.preventDefault();
  }
};

export const onPaste = (e, disablePaste, disableFloat) => {
  if (disablePaste) {
    // if paste is disabled prevent value
    e.preventDefault();
  } else {
    const copiedText = e.clipboardData.getData("text");
    // if is integer its fine
    if (isInt(copiedText)) {
      return;
    }
    // if float is disabled prevent paste
    if (disableFloat) {
      e.preventDefault();
      return;
    }
    const numericalVal = parseFloat(copiedText);
    if (!Number.isNaN(numericalVal) && !disableFloat) {
      return;
    }
    e.preventDefault();
  }
};
