/* eslint-disable consistent-return */
import { useEffect, useState, useMemo } from "react";
import { Socket } from "phoenix";

const baseSocketUrl = process.env.GATSBY_PUBLIC_APP_SOCKET_URL;

const useChannel = ({ auctionId }) => {
  const [data, setData] = useState(null);

  const socket = useMemo(() => {
    const s = new Socket(baseSocketUrl);
    s.connect();
    return s;
  }, []);

  useEffect(() => {
    let phoenixChannel;
    console.log("Connecting");
    console.log(socket, auctionId);
    if (socket && auctionId) {
      const channelId = `auction:${auctionId}`;
      phoenixChannel = socket.channel(channelId);
      phoenixChannel
        .join()
        .receive("ok", () => {
          console.log("JOINED CHANNEL");
        })
        .receive("error", (resp) => {
          console.log("Unable to join", resp);
        })
      
      phoenixChannel.on("bid", (payload) => {
        console.log("RECEIVED BID");
        setData(payload);
      })
    }

    return () => {
      if (phoenixChannel) {
        phoenixChannel.leave();
      }
    };
  }, [auctionId, socket]);

  return [data];
};

export default useChannel;
