import { PublicAuctionResponse } from "../../../store/events/actions";

/**
 * Checks for minimum bid increment requirements
 * @param bid (number)
 * @param publicAuction (PublicAuctionResponse)
 * @returns true/false (boolean)
 */
const checkMinBidRequirements = (bid, publicAuction: PublicAuctionResponse) => {
  let i = 0;
  if (publicAuction.data.auction.bid_count === 0) {
    i = 0;
  } else {
    i = 1;
  }
  if (
    bid >=
    (publicAuction.data.auction.current_bid_price ||
      publicAuction.data.auction.price) +
    (publicAuction.data.auction.increment * i)
  ) {
    return true;
  }
  return false;
};

export { checkMinBidRequirements };
