import React from 'react';
import CloseButton from '../PageComponents/Dashboard/Auctions/AuctionCreation/internal/CloseButton';

type ModalProps = {
  show: boolean,
  onHide: () => void,
  children: React.ReactNode,
}

const Modal: React.FC<ModalProps> = (props) => {

  return (
    <>
      {props.show && (
        <div className="fixed bg-opacity-70 bg-black inset-0 z-50">
          <div className="w-full h-full lg:w-1/2 lg:max-w-md bg-white lg:h-auto lg:rounded-lg shadow-sm mx-auto lg:mt-12 flex flex-col justify-center items-center p-10 relative">
            {/* <CloseButton
              onClick={() => props.onHide()}
              extraClasses="absolute-[!important] top-4 right-4"
            /> */}
            {props.children}
          </div>
        </div>
      )}
    </>
  )
}

export default Modal;