import React, { useState } from 'react';

const LeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3" className="-ml-1 h-7 w-7"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
)

const RightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3" className="ml-1 h-7 w-7"><path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path></svg>
)

const Carousel = ({
  images
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDirectionChange = (direction) => {
    if (currentIndex === 0 && direction === 'left') {
      setCurrentIndex(images.length - 1);
      return;
    }
    if (currentIndex === images.length - 1 && direction === 'right') {
      setCurrentIndex(0)
      return;
    }
    let directionIndicator = 1;
    
    if (direction === 'right') {
      setCurrentIndex(currentIndex + 1);
      return;
    }
    setCurrentIndex(currentIndex - 1);
    return;
  }

  return (
    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
      <div className="relative w-full h-full overflow-x-hidden flex rounded-xl">
        {images.map((img, key) => (
          <div
            className="w-full h-full inline-block flex-none"
            style={{
              left: `${100 * key}%`,
              right: `${100 * key}%`,
              transform: currentIndex === key ? `translateX(-${100 * key}%)` : 'none',
            }}
          >
            <img
              className="h-full w-full object-cover"
              id={`img-${key}`}
              src={img}
              alt={`image-${key}`}
            />
          </div>
        ))}
        <button onClick={() => handleDirectionChange('left')} className="absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-black hover:bg-opacity-10 active:bg-white active:bg-opacity-30 grid place-items-center bg-black bg-opacity-20">
          <LeftIcon />
        </button>
        <button onClick={() => handleDirectionChange('right')} className="absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-black hover:bg-opacity-10 active:bg-white active:bg-opacity-30 grid place-items-center bg-black bg-opacity-20">
          <RightIcon />
        </button>
        <div
          className="absolute bottom-4 z-50 flex -translate-x-2/4 gap-2"
          style={{
            left: `calc(50% - ${1 * images.length - 1}rem)`
          }}
        >
          {images.map((_, key) => (
            <span key={`bubble-${key}`} className={`block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] ${currentIndex === key ? 'bg-white' : 'bg-white opacity-50'}`} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Carousel;
