import React, { useContext, useRef, useState } from 'react';
import useModalShow from '../Hooks/useModalShow';
import Modal from '../Modal';

type ModalContextType = {
  showConfirmation: (title: string, message: string) => Promise<boolean>,
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode
}

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
  const [content, setContent] = useState<{ title: string, message: string} | null>();
  const {setShow, show, onHide} = useModalShow();
  const resolver = useRef<Function>();

  const handleShow = (title: string, message: string): Promise<boolean> => {
    setContent({
        title,
        message
    });
    setShow(true);
    return new Promise(function (resolve) {
        resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow
  };

  const handleOk = () => {
      resolver.current && resolver.current(true);
      onHide();
  };

  const handleCancel = () => {
      resolver.current && resolver.current(false);
      onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}
      {content && (
        <Modal show={show} onHide={onHide}>
          <h2>
            <label>{content.title}</label>
          </h2>
          <div className="text-center">
            <label>{content.message}</label>
          </div>
          <div className="flex flex-row gap-8 mt-4">
            <button className="bg-white text-bidddyPink rounded-full p-3" onClick={handleCancel}>Cancel</button>
            <button className="p-3 bg-bidddyPink rounded-full text-white w-24" onClick={handleOk}>OK</button>
          </div>
        </Modal>
      )}
    </ConfirmationModalContext.Provider>
  )
};

export const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);
export default ConfirmationModalContextProvider;